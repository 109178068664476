import { readState } from "@/__main__/app-state.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import {
  EVENT_LOL_TIMELINE_LOADED,
  QUEUE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import lolFetchPostmatchDataRefs from "@/game-lol/fetches/lol-fetch-postmatch-data.refs.mjs";
import { getPreferredPostmatchComparisonRank } from "@/game-lol/utils/actions.mjs";
import { isSRCoachableQueue } from "@/game-lol/utils/coaching-utils.mjs";
import { separateDerivedRiotID } from "@/game-lol/utils/derived-id.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import { findPlayer } from "@/game-lol/utils/match-utils.mjs";
import QueueSymbol, {
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";
import { isARAMQueue } from "@/game-lol/utils/util.mjs";
import { devError } from "@/util/dev.mjs";
import retry from "@/util/retry-promise.mjs";

const timelineSent = {};

async function fetchData(
  [region, name, matchId, tab, tabId],
  _searchParams,
  state,
) {
  const isPBE = region?.toLowerCase() === "pbe1";
  if (isPBE) return;

  const isOwnProfile = isLinkedSummoner(readState, region, name);
  const [gameName, tagLine] = separateDerivedRiotID(name);

  // Await profile immediately, its required to find player info in the match
  // You can test this by running the integration test without this awaited
  // The test will fail to find the player and analyze the results of the match
  let localPlayerProfile = {};
  try {
    localPlayerProfile = await API.PLAYER.getProfile({
      region,
      gameName,
      tagLine,
    });
  } catch (err) {
    devError("FAILED TO LOAD LOCAL PROFILE", err);

    const [gameName, tagLine] = separateDerivedRiotID(name);
    localPlayerProfile = {
      riotAccount: {
        gameName,
        tagLine,
      },
    };
  }

  let match;
  if (!isPBE && !state.isIncomplete) {
    match = await API.PLAYER.getMatch({
      region,
      matchId,
      options: { shouldFetchIfPathExists: true },
    }).catch(devError);
  }

  if (!match) return;

  const matchQueueSymbol = QueueSymbol(match.queueId);
  const isARAM = isARAMQueue(matchQueueSymbol);

  // Should fetch additional requests like timeline, coaching data, etc.
  // Non-coachable matches dont need to request all this additional data
  const participants = match.participants || [];
  const localPlayer = findPlayer(
    {
      riotAccount: {
        gameName,
        tagLine,
      },
    },
    match,
  );
  const localPlayerRole = localPlayer?.teamPosition;
  const localPlayerChampionId = localPlayer?.championId;

  // Dont fetch profiles if we are using the new rust backend
  const participantsReq = participants
    .filter((p) => {
      // Dont fetch local player profile, we already did that above
      const samePUUID = p.puuid === localPlayerProfile?.puuid;
      const sameSummonerName =
        p.summonerName === localPlayerProfile?.summonerName;
      const sameRiotID =
        p.riotIdGameName === localPlayerProfile?.riotAccount?.gameName &&
        p.riotIdTagline === localPlayerProfile?.riotAccount?.tagLine;

      if (samePUUID || sameSummonerName || sameRiotID) return false;

      return true;
    })
    .map((p) =>
      API.PLAYER.getProfile({
        region,
        gameName: p.riotIdGameName,
        tagLine: p.riotIdTagline,
        options: {
          shouldFetchIfPathExists: false,
        },
      }),
    );

  const isCoachable = isSRCoachableQueue(match) && localPlayerChampionId;

  // Timeline
  if (!isPBE) {
    const timelineStart = performance.now();
    retry(() => API.PLAYER.getMatchTimeline({ region, matchId }), {
      maxRetries: 3,
      interval: 1000,
    })
      .then((timeline) => {
        if (state.isProgrammatic && !timelineSent[matchId]) {
          const timelineEnd = performance.now();
          const timeTaken = (timelineEnd - timelineStart) / 1000; // seconds
          eventBus.emit(EVENT_LOL_TIMELINE_LOADED, {
            hasTimeline: (timeline?.frames?.length ?? 0) > 0,
            timeTaken,
            region,
            matchId,
          });
          timelineSent[matchId] = true;
        }
      })
      .catch(devError);
  }

  if (isCoachable) {
    const preferredComparisonRank = getPreferredPostmatchComparisonRank();
    const localPlayerRank = state?.comparisonRank
      ? RankSymbol(state.comparisonRank)
      : preferredComparisonRank ||
        (localPlayerProfile?.latestRanks || []).find(
          (q) => q.queue === QUEUE_SYMBOLS.rankedSoloDuo,
        )?.tier ||
        DEFAULT_COMPARISON_RANK;

    API.AGGREGATE.getChampionByMinuteStats({
      championId: localPlayerChampionId,
      queue: QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.rankedSoloDuo].gql,
      tier: localPlayerRank,
      role: localPlayerRole,
    });

    // getData(
    //   API.getStatsByMinute(statsVariables),
    //   LolStatsByMinute,
    //   ["lol", "championStatsByMin", coachingStatsKey(statsVariables)],
    //   { shouldFetchIfPathExists: false },
    // ).catch((error) => {
    //   devError("FAILED TO SIDE-LOAD DIVISION STATS", error);
    // });
  } else if (isARAM) {
    API.AGGREGATE.getChampionByMinuteStats({
      championId: localPlayerChampionId,
      queue: QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.aram].gql,
    });
  }

  const additional = lolFetchPostmatchDataRefs.fetchFunctions.map((fn) =>
    fn({ gameId: matchId, isOwnProfile, tab, tabId }),
  );

  return Promise.all([participantsReq, ...additional]);
}

export default fetchData;
